<template>
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
        <component v-bind:is="layout"></component>
    </div>
</template>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';
    import custLayout from './layouts/cust-layout.vue';
    import custAuthLayout from './layouts/cust-auth-layout.vue';
    import axios from 'axios';
    import '@/assets/sass/app.scss';

    export default {
        metaInfo: {
            title: 'Home',
            titleTemplate: '%s',
        },
        components: {
            app: appLayout,
            auth: authLayout,
            cust: custLayout,
            cust_auth: custAuthLayout,
        },
        computed: {
            layout() {
                return this.$store.getters.layout;
            },
        },
        data() {
            return {};
        },
        mounted() {
            axios
                .get('getCenterDetails/' + window.location.host)
                .then((res) => {
                    if (res.data.error) {
                        console.log(res);
                    } else {
                        this.$store.dispatch('center', res.data.data);
                        this.$store.dispatch('hostname', res.data.data.bucket_url);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        methods: {},
    };
</script>

<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <li class="menu">
                    <router-link tag="li" to="/staff/home" class="menu" @click.native="toggleMobileMenu">
                        <a class="dropdown-toggle">
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-home"
                                >
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                </svg>
                                <span>Dashboard</span>
                            </div>
                        </a>
                    </router-link>
                </li>

                <router-link tag="li" to="/staff/check-in" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-user-check"
                            >
                                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx="8.5" cy="7" r="4" />
                                <polyline points="17 11 19 13 23 9" />
                            </svg>

                            <span>Check In</span>
                        </div>
                    </a>
                </router-link>
                <li class="menu">
                    <a href="#new-book" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-calendar"
                            >
                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                                <line x1="16" y1="2" x2="16" y2="6" />
                                <line x1="8" y1="2" x2="8" y2="6" />
                                <line x1="3" y1="10" x2="21" y2="10" />
                            </svg>
                            <span>Book Now</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="new-book" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <router-link tag="li" v-if="modulesArray && modulesArray.includes('class')" to="/staff/class-booking" @click.native="toggleMobileMenu"> <a>Classes / PT </a> </router-link>

                            <router-link v-if="modulesArray && modulesArray.includes('venue')" tag="li" to="/staff/venuebooking" @click.native="toggleMobileMenu"><a> Venues </a> </router-link>
                            <router-link v-if="modulesArray && modulesArray.includes('pool')" tag="li" to="/staff/poolbooking" @click.native="toggleMobileMenu"><a> Pool </a> </router-link>

                            <router-link v-if="modulesArray && modulesArray.includes('course')" tag="li" to="/staff/course-booking" @click.native="toggleMobileMenu"> <a>Courses</a> </router-link>

                            <router-link v-if="modulesArray && modulesArray.includes('membership')" tag="li" to="/staff/buy-membership" @click.native="toggleMobileMenu">
                                <a>Membership</a>
                            </router-link>
                        </ul>
                    </b-collapse>
                </li>

                <router-link tag="li" to="/staff/pos" class="menu" @click.native="toggleMobileMenu" v-if="modulesArray && modulesArray.includes('pos')">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-bag"
                            >
                                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
                                <line x1="3" y1="6" x2="21" y2="6" />
                                <path d="M16 10a4 4 0 0 1-8 0" />
                            </svg>
                            <span>POS</span>
                        </div>
                    </a>
                </router-link>

                <router-link tag="li" to="/staff/upcomming-booking" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-bag"
                            >
                                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
                                <line x1="3" y1="6" x2="21" y2="6" />
                                <path d="M16 10a4 4 0 0 1-8 0" />
                            </svg>
                            <span>Bookings</span>
                        </div>
                    </a>
                </router-link>
                <li class="menu">
                    <a href="#course-hi" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-list"
                            >
                                <line x1="8" y1="6" x2="21" y2="6" />
                                <line x1="8" y1="12" x2="21" y2="12" />
                                <line x1="8" y1="18" x2="21" y2="18" />
                                <line x1="3" y1="6" x2="3.01" y2="6" />
                                <line x1="3" y1="12" x2="3.01" y2="12" />
                                <line x1="3" y1="18" x2="3.01" y2="18" />
                            </svg>
                            <span>Courses</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="course-hi" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <router-link
                                tag="li"
                                v-if="user && user.user_type == 'admin' && modulesArray && modulesArray.includes('course')"
                                to="/staff/course-master"
                                @click.native="toggleMobileMenu"
                            >
                                <a> Manage Course </a>
                            </router-link>
                            <router-link tag="li" to="/staff/booked-courses" v-if="modulesArray && modulesArray.includes('course')" @click.native="toggleMobileMenu"><a>Booked Course</a> </router-link>
                        </ul>
                    </b-collapse>
                </li>
                <router-link tag="li" to="/staff/customers" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-user-plus"
                            >
                                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx="8.5" cy="7" r="4" />
                                <line x1="20" y1="8" x2="20" y2="14" />
                                <line x1="23" y1="11" x2="17" y2="11" />
                            </svg>
                            <span>Customers</span>
                        </div>
                    </a>
                </router-link>
                <!-- <router-link tag="li" to="/staff/poolbookinglist" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-bag"
                            >
                                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
                                <line x1="3" y1="6" x2="21" y2="6" />
                                <path d="M16 10a4 4 0 0 1-8 0" />
                            </svg>
                            <span>Pool Booking List</span>
                        </div>
                    </a>
                </router-link> -->
                <router-link tag="li" to="/staff/pool_batch_booking" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-bag"
                            >
                                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
                                <line x1="3" y1="6" x2="21" y2="6" />
                                <path d="M16 10a4 4 0 0 1-8 0" />
                            </svg>
                            <span>Pool Batch Booking</span>
                        </div>
                    </a>
                </router-link>
                <router-link tag="li" to="/staff/Class_Booking_list" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-bag"
                            >
                                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
                                <line x1="3" y1="6" x2="21" y2="6" />
                                <path d="M16 10a4 4 0 0 1-8 0" />
                            </svg>
                            <span>Upcoming Bookings</span>
                        </div>
                    </a>
                </router-link>
                <!-- <router-link tag="li" to="/staff/subscriptions" class="menu" @click.native="toggleMobileMenu">
          <a class="dropdown-toggle">
            <div class="">
              <b-icon icon="credit-card-fill" />
              <span>Subscription</span>
            </div>
          </a>
        </router-link> -->
                <li class="menu">
                    <a href="#packages" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-users"
                            >
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx="9" cy="7" r="4" />
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                            </svg>
                            <span>Membership</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="packages" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <router-link v-if="user && user.user_type == 'admin' && modulesArray && modulesArray.includes('membership')" tag="li" to="/staff/packages" @click.native="toggleMobileMenu"
                                ><a>Membership Type</a>
                            </router-link>

                            <router-link v-if="modulesArray && modulesArray.includes('membership')" tag="li" to="/staff/active-membership" @click.native="toggleMobileMenu">
                                <a>Purchased Membership</a></router-link
                            >
                        </ul>
                    </b-collapse>
                </li>
                <li class="menu" v-if="user && user.user_type == 'admin'">
                    <a href="#smasters" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-tool"
                            >
                                <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                            </svg>
                            <span>Service Setup</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="smasters" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <!-- <router-link tag="li" to="/staff/service-master" @click.native="toggleMobileMenu"><a>Manage
                                    Services</a> </router-link>
                            <router-link tag="li" to="/staff/service-sessions" @click.native="toggleMobileMenu">
                                <a>Manage Sessions</a> </router-link> -->
                            <!-- <router-link tag="li" to="/staff/course-master" @click.native="toggleMobileMenu"><a>Manage Courses </a> </router-link> -->
                            <router-link v-if="modulesArray && modulesArray.includes('class')" tag="li" to="/staff/class-master" @click.native="toggleMobileMenu"><a>Manage Classes </a> </router-link>
                            <router-link v-if="modulesArray && modulesArray.includes('pt')" tag="li" to="/staff/pt-master" @click.native="toggleMobileMenu"><a>Manage PT </a> </router-link>
                            <router-link v-if="modulesArray && modulesArray.includes('payg')" tag="li" to="/staff/pay-as-you-go" @click.native="toggleMobileMenu"><a>Manage PAYG </a> </router-link>
                            <router-link v-if="modulesArray && modulesArray.includes('pool')" tag="li" to="/staff/pool" @click.native="toggleMobileMenu"><a>Manage Pool </a> </router-link>
                            <router-link v-if="modulesArray && modulesArray.includes('venue')" tag="li" to="/staff/venues" @click.native="toggleMobileMenu"><a>Manage Venues </a> </router-link>
                        </ul>
                    </b-collapse>
                </li>

                <li class="menu" v-if="user && user.user_type == 'admin'">
                    <a href="#store" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
                                <path
                                    d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"
                                />
                            </svg>
                            <span>Store</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="store" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <router-link tag="li" to="/staff/purchase" @click.native="toggleMobileMenu"><a>Stock Entry</a> </router-link>
                            <router-link tag="li" to="/staff/current-stock" @click.native="toggleMobileMenu"><a>Current Stock</a> </router-link>
                            <router-link tag="li" to="/staff/store-audit" @click.native="toggleMobileMenu"><a>Stock Audit</a> </router-link>
                        </ul>
                    </b-collapse>
                </li>

                <!-- <router-link v-if="user && user.user_type == 'admin'" tag="li" to="/staff/voucher" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-clipboard"
                            >
                                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                                <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
                            </svg>
                            <span>Vouchers</span>
                        </div>
                    </a>
                </router-link> -->
                <li class="menu">
                    <a href="#Voucher-hi" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-clipboard"
                            >
                                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                                <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
                            </svg>
                            <span>Vouchers</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="Voucher-hi" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <router-link v-if="user && user.user_type == 'admin'" tag="li" to="/staff/voucher" class="menu" @click.native="toggleMobileMenu"> <a> Offer Vouchers </a> </router-link>
                            <!-- <router-link tag="li" to="/staff/refundvouchers" @click.native="toggleMobileMenu"><a>Refund Vouchers</a> </router-link> -->
                        </ul>
                    </b-collapse>
                </li>
                <li class="menu" v-if="user && user.user_type == 'admin'">
                    <a href="#users" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-users"
                            >
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx="9" cy="7" r="4" />
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                            </svg>
                            <span>Manage Staff</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="users" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <router-link tag="li" to="/staff/users" @click.native="toggleMobileMenu"><a>Staff List</a> </router-link>
                        </ul>
                    </b-collapse>
                </li>

                <li class="menu" v-if="user && user.user_type == 'admin'">
                    <a href="#settings" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-settings"
                            >
                                <circle cx="12" cy="12" r="3" />
                                <path
                                    d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                                />
                            </svg>
                            <span>Settings</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="settings" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <router-link tag="li" v-if="modulesArray && modulesArray.includes('pos')" to="/staff/products" @click.native="toggleMobileMenu"><a>Products</a> </router-link>
                            <router-link tag="li" v-if="modulesArray && modulesArray.includes('pos')" to="/staff/category" @click.native="toggleMobileMenu"><a>Category</a> </router-link>
                            <router-link tag="li" to="/staff/app-settings" @click.native="toggleMobileMenu"> <a>Application Settings</a></router-link>
                            <router-link tag="li" to="/staff/authipay-settings" @click.native="toggleMobileMenu"> <a>Stripe Settings</a></router-link>
                            <router-link tag="li" to="/staff/email-template" @click.native="toggleMobileMenu"><a>Email Notifications</a> </router-link>
                            <router-link tag="li" to="/staff/widget-settings" @click.native="toggleMobileMenu"><a>Widget Settings</a> </router-link>
                            <router-link tag="li" to="/staff/service_announcement" @click.native="toggleMobileMenu"><a>Service Announcement</a> </router-link>
                            <router-link tag="li" to="/staff/banner" @click.native="toggleMobileMenu"><a>Banner</a> </router-link>
                            <router-link tag="li" to="/staff/faq" @click.native="toggleMobileMenu"><a>FAQ</a> </router-link>
                        </ul>
                    </b-collapse>
                </li>

                <li class="menu">
                    <a href="#reports" v-b-toggle class="dropdown-toggle" @click.prevent>
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-pie-chart"
                            >
                                <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                                <path d="M22 12A10 10 0 0 0 12 2v10z" />
                            </svg>
                            <span>Reports</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>
                    <b-collapse id="reports" accordion="menu">
                        <ul class="collapse submenu list-unstyled show">
                            <router-link tag="li" to="/staff/x-report" @click.native="toggleMobileMenu"><a>Day End Report</a> </router-link>
                            <router-link tag="li" to="/staff/reports" @click.native="toggleMobileMenu"><a>Payments</a> </router-link>
                            <!-- <router-link tag="li" to="/staff/refund_report" @click.native="toggleMobileMenu"><a>Refund Report</a> </router-link> -->
                            <router-link tag="li" to="/staff/credit_refund" @click.native="toggleMobileMenu"><a>Credit Note Refund</a> </router-link>
                            <router-link tag="li" to="/staff/coursesales" @click.native="toggleMobileMenu"><a>Course Sales</a> </router-link>
                            <router-link tag="li" to="/staff/poolsales" @click.native="toggleMobileMenu"><a>Pool Sales</a> </router-link>
                            <router-link tag="li" to="/staff/venuesales" @click.native="toggleMobileMenu"><a>Venue Sales</a> </router-link>
                            <router-link tag="li" to="/staff/classsales" @click.native="toggleMobileMenu"><a>Class Sales</a> </router-link>
                            <router-link tag="li" to="/staff/membershipsales" @click.native="toggleMobileMenu"><a>Membership Sales</a> </router-link>
                            <router-link tag="li" to="/staff/retailsales" @click.native="toggleMobileMenu"><a>Retail Sales</a> </router-link>
                            <router-link tag="li" to="/staff/paylater" @click.native="toggleMobileMenu"><a>Pay Later Report</a> </router-link>
                            <router-link tag="li" to="/staff/checkinreport" @click.native="toggleMobileMenu"><a>Check-In Report</a> </router-link>
                            <router-link tag="li" :to="`/staff/CustomerCheckIn/${selectedDate}`" @click.native="toggleMobileMenu"><a>Customer CheckIn</a> </router-link>
                            <router-link tag="li" to="/staff/revenue_report" @click.native="toggleMobileMenu"><a>Revenue Report</a> </router-link>
                            <router-link tag="li" to="/staff/stock_report" @click.native="toggleMobileMenu"><a>Stock Report</a> </router-link>
                            <router-link tag="li" to="/staff/vat_report" @click.native="toggleMobileMenu"><a>Vat Report</a> </router-link>
                            <router-link tag="li" to="/staff/auto_subscription_report" @click.native="toggleMobileMenu"><a>Auto Subscription Report</a> </router-link>
                            <router-link tag="li" to="/staff/cashsheet_report" @click.native="toggleMobileMenu"><a>Cashsheet Report</a> </router-link>
                            <router-link tag="li" to="/staff/category_report" @click.native="toggleMobileMenu"><a>Category Report</a> </router-link>
                            <router-link tag="li" to="/staff/audit_report" @click.native="toggleMobileMenu"><a>Audit Report</a> </router-link>
                            <router-link tag="li" to="/staff/deferred_income" @click.native="toggleMobileMenu"><a>Deferred Income</a> </router-link>
                            <router-link tag="li" to="/staff/deferred_income_course" @click.native="toggleMobileMenu"><a>Deferred Income Course</a> </router-link>
                            <router-link tag="li" to="/staff/cancellation_&_refund" @click.native="toggleMobileMenu"><a>Cancellation & Refund Report</a> </router-link>
                            <router-link tag="li" to="/staff/membership_report" @click.native="toggleMobileMenu"><a>Membership Report</a> </router-link>
                        </ul>
                    </b-collapse>
                </li>

                <router-link tag="li" to="/staff/coming-soon" class="menu d-none" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-help-circle"
                            >
                                <circle cx="12" cy="12" r="10" />
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                <line x1="12" y1="17" x2="12.01" y2="17" />
                            </svg>
                            <span>Helpdesk</span>
                        </div>
                    </a>
                </router-link>
                <router-link tag="li" to="/staff/clover-device" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                stroke-width="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-smartphone"
                            >
                                <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
                                <line x1="12" y1="18" x2="12.01" y2="18"></line>
                            </svg>
                            <span>Clover Device</span>
                        </div>
                    </a>
                </router-link>
                <router-link tag="li" to="#" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle" @click.prevent="logout">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-log-out"
                            >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                <polyline points="16 17 21 12 16 7" />
                                <line x1="21" y1="12" x2="9" y2="12" />
                            </svg>
                            <span>Logout</span>
                        </div>
                    </a>
                </router-link>
                <!--</ul>-->
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>
<script>
    import axios from 'axios';
    import { mapGetters } from 'vuex';
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    export default {
        data() {
            return { menu_collapse: 'dashboard', modules: [], modulesArray: [], centerId: null, Center_Id: null, selectedDate: '' };
        },

        // watch: {
        //     $route(to) {
        //         if (to) {
        //             const selector = document.querySelector('#sidebar a[href="' + to.path + '"]');
        //             const ul = selector.closest('ul.collapse');
        //             if (!ul) {
        //                 const ele = document.querySelector('.dropdown-toggle.not-collapsed');
        //                 if (ele) {
        //                     ele.click();
        //                 }
        //             }
        //         }
        //     }
        // },
        created() {
            var now = new Date();
            var day = ('0' + now.getDate()).slice(-2);
            var month = ('0' + (now.getMonth() + 1)).slice(-2);
            var today = now.getFullYear() + '-' + month + '-' + day;
            this.selectedDate = today;
            // console.log('center details::::::::::::', this.center, this.center.id, this.$store.state.center.id);
            if (!this.$store.state.center) {
                axios
                    .get('getCenterDetails/' + window.location.host)
                    .then((res) => {
                        if (res.data.error) {
                            console.log(res);
                        } else {
                            this.$store.dispatch('center', res.data.data);
                            this.$store.dispatch('hostname', res.data.data.bucket_url);
                            console.log(res.data.data.id);
                            this.getcentermodules(res.data.data.id);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.getcentermodules(this.center.id);
            }
        },
        mounted() {
            const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
            if (selector) {
                const ul = selector.closest('ul.collapse');
                if (ul) {
                    let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                    if (ele) {
                        ele = ele[0];
                        setTimeout(() => {
                            ele.click();
                        });
                    }
                } else {
                    selector.click();
                }
            }
        },

        methods: {
            getcentermodules() {
                axios
                    .get('publicGetCenterDetails/' + this.$store.state.center.id)
                    .then((res) => {
                        if (res.data.error) {
                            console.log(res);
                        } else {
                            this.modules = res.data.data.modules;
                            try {
                                if (this.modules) {
                                    this.modulesArray = JSON.parse(this.modules);
                                    this.$store.dispatch('setModulesArray', this.modulesArray);
                                }
                            } catch (error) {
                                console.error('Error parsing modules:', error);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            isNameAvailable(name) {
                const modulesArray = this.$store.state.modulesArray;
                return modulesArray && modulesArray.includes(name);
            },
            toggleMobileMenu() {
                if (window.innerWidth < 991) {
                    this.$store.commit('toggleSideBar', !this.$store.state.is_show_sidebar);
                }
            },
            logout() {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                axios
                    .get('logout')
                    .then((res) => {
                        if (res.data.error) {
                            this.$router.push('/staff/login').catch(() => {
                                this.$router.push('/staff/login').catch();
                            });
                        } else {
                            Window.location.href = '/staff/login';
                        }
                    })
                    .catch(() => {
                        this.$router.push('/staff/login').catch();
                    });
                localStorage.removeItem('token');
                this.$store.dispatch('user', null);
                this.$store.state.cloverConnector.dispose();
            },
        },
        computed: {
            ...mapGetters(['center']),
            ...mapGetters(['user']),
        },
    };
</script>
<style>
    nav#sidebar {
        margin-top: 0.5rem;
    }
</style>

<template>
    <div style="padding: 0px" class="container-fluid">
        <div style="border: 0px" class="card" v-if="center">
            <div style="border: 0px" class="card-body1">
                <div class="col-md-12 text-center" style="height: 100vw; margin-top: 100px" v-if="$route.fullPath == '' || $route.fullPath == '/'">
                    <h3>Welcome</h3>
                    <p>You need to visit on following link.</p>
                    <router-link tag="a" to="/staff/login" class="btn btn-success m-1">Staff Login</router-link>
                    <router-link tag="a" to="/customer/register" class="btn btn-success m-1">Customer Login / Register</router-link>
                </div>
                <router-view></router-view>
            </div>
        </div>
        <div class="col-12" v-else>
            <h3 class="text-center m-5">Please Visit to registered center subdomain.</h3>
        </div>
    </div>
</template>

<script>
    import '@/assets/sass/authentication/auth.scss';
    import { mapGetters } from 'vuex';
    import axios from 'axios';

    export default {
        name: 'Home',
        data() {
            return {
                baseurl: this.$store.state.hostname,
                title: '',
            };
        },
        metaInfo() {
            const ctr = this.title;
            return {
                title: `${ctr}`,
            };
        },
        mounted() {
            // this.$router.push('/staff/login').catch(() => {
            //     this.$router.push('/staff/login');
            // });
        },
        beforeCreate() {
            if (!this.$store.state.center) {
                axios
                    .get('getCenterDetails/' + window.location.host)
                    .then((res) => {
                        if (res.data.error) {
                            console.log(res);
                            window.location.href = 'https://app.centumx.ie';
                        } else {
                            this.$store.dispatch('center', res.data.data);
                            this.$store.dispatch('hostname', res.data.data.bucket_url);
                            // const favicon = document.getElementById("favicon");
                            // favicon.href = this.baseurl+res.data.data.logo;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        methods: {},
        computed: {
            ...mapGetters(['center']),
            pathname() {
                return window.location.pathname;
            },
        },
    };
</script>
